import React, {FunctionComponent, ReactNode, useEffect} from 'react';
import {Stack, Text, Link, Image, FontWeights, IImageProps, ImageFit, Separator} from '@fluentui/react';
import cv from './cv-rect.jpg'
import './App.css';
import {getTheme} from '@fluentui/react';
import {useMediaQuery} from 'react-responsive';

const theme = getTheme();


export const App: React.FunctionComponent = () => {
    const isPrint = useMediaQuery(
        {minWidth: '1px'},
        {type: 'print'}
    )
    const isTabletOrMobile = useMediaQuery(
        {maxWidth: '1224px'},
    ) && !isPrint;
    useEffect(() => {
            document.body.style.backgroundColor = theme.palette.neutralLighter
        }
    );

    useEffect(() => {
        console.info()
    }, []);

    return (
        <div style={{
            marginTop: '0.5em', marginLeft: '5%', width: '90%', justifyContent: 'center',
            fontSize: 'small', backgroundColor: isPrint ? 'red' : ''
        }}>

            <Introduction/>

            <Stack horizontal={!isTabletOrMobile}>
                <Stack style={{
                    width: isTabletOrMobile ? '100%' : '18%',
                    // marginLeft: isTabletOrMobile ? '2%' : '2em'
                }}>


                    <TitledSection name='Contact'>
                        <div><Link href='mailto:karol@beresniewicz.dev'>karol@beresniewicz.dev</Link></div>
                        <div><Text>+48 795 599 996</Text></div>
                        <div><Text>Located in Gdańsk, Poland</Text></div>
                    </TitledSection>

                    <TitledSection name='Skills'>
                        <Skills/>
                    </TitledSection>
                    <LanguagesSection/>

                    <OnDesktop> <AdditionalInfoSection/> </OnDesktop>

                </Stack>


                <TitledSection name='Experience' styles={{marginLeft: isTabletOrMobile ? '0' : '2em'}}>
                    {data.experience.map(e => JobSection(e, data.experience.indexOf(e) !== data.experience.length - 1))}
                </TitledSection>


                <Stack style={{width: isTabletOrMobile ? '100%' : '40%', marginLeft: isTabletOrMobile ? '0' : '2em'}}>
                    <TitledSection name='Education'>
                        <Text styles={boldStyle} variant='large'> Engineering degree in Informatics</Text>
                        <Text variant='large'>Gdańsk University of Technology, Faculty of Electronics,
                            Telecommunications
                            and
                            Informatics</Text>
                        <Text {...dateStyle}>2017-2021</Text>
                    </TitledSection>

                    <TitledSection name='Organizations'>
                        <>
                            {data.organizations.map(a => JobSection(a, data.organizations.indexOf(a) !== data.organizations.length - 1))}
                        </>
                    </TitledSection>

                    <TitledSection name='Achievements'>
                        <AchievementsSectionBody/>
                    </TitledSection>

                    <TitledSection
                        name='Hobbies'
                        styles={{marginBottom: '2em'}}>
                        <Text>
                            Inline skating, programming, racket sports
                        </Text>
                    </TitledSection>
                    <OnMobile><AdditionalInfoSection/></OnMobile>
                </Stack>
            </Stack></div>
    );
};

interface IProject {
    name?: string,
    duties: string[],
    domain: string
}

interface IJobExperience {
    company: string;
    position: string;
    when: Array<{ since: Date, until?: Date }>;
    projects: IProject[] | string[];
}


interface SkillData {
    name: string;
}

const CreateSkillData = (skillName: string): SkillData => {
    return {
        name: skillName
    }
}
const data = {
    experience: [
        {
            company: "Vocaly",
            position: "Backend developer",
            when: [{
                since: new Date(2021, 4, 1),
            }],
            projects: [
                {
                    name: 'Backend for a vocal training app',
                    duties: [
                        "Designing technological solutions to business problems",
                        "Developing, testing and maintaining a container based workload with kubernetes",
                        "Integrating external services",
                        "Maintaining a software delivery process"
                    ],
                    domain: 'A startup company developing an application allowing their users to train their voice.'
                }
            ]
        } as IJobExperience,
        {
            company: "Avanade",
            position: "Senior Analyst, Full stack development (.NET)",
            when: [{
                since: new Date(2021, 9, 1),
                until: new Date(2022, 4, 1),
            }],
            projects: [
                {
                    name: 'Certification and management app',
                    duties: [
                        "Expansion and maintenance of .NET Core + Angular Solution",
                        "Maintaining the hosted solution on Azure",
                        "Taking part in agile development process"],
                    domain: 'Energy and gas'
                }
            ]
        } as IJobExperience,
        {
            company: "Simployer Tech Polska",
            position: "Fullstack .NET Developer",
            when: [{
                since: new Date(2020, 9, 1),
                until: new Date(2021, 9, 1),
            }],
            projects: [
                {
                    name: 'Simployer App',
                    duties: [
                        "Maintenance and extension of legacy .NET application",
                        "Rewrite of a part of an app to React",
                        "Work in an international environment"
                    ],
                    domain: 'An HR Solution aiming to streamline various aspects of everyday work life for employees and employers.'
                }
            ]
        } as IJobExperience,
        {
            company: "Goyello, now Aspire Systems",
            position: ".NET Developer",
            when: [{
                since: new Date(2018, 7, 1),
                until: new Date(2018, 10, 1)
            },
                {
                    since: new Date(2019, 5, 1),
                    until: new Date(2020, 9, 1)
                }],
            projects: [
                {
                    name: 'Trade deal chain certification',
                    duties: [
                        "Development and maintenance .NET Core 2.1/3.1 and Angular 2+ application for a foreign client",
                        "Building and maintaining an app hosted on Azure",
                        "Active participation in SCRUM development process",
                        "Monitoring and tuning of EF Core generated SQL Queries"
                    ],
                    domain: 'A supply chain tracking application allowing auditors to inspect transactions reported by farmers and traders.'
                },
                {
                    name: 'Wolontariat Gdańsk',
                    duties: [
                        "Development of Xamarin Forms application",
                        "Integration and building a wrapper over legacy systems"
                    ],
                    domain: 'A cross-platform mobile app enabling people to discover the possibilities to help others in the city of Gdańsk.'
                }

            ]
        }
    ]
    ,
    organizations: [
        {
            company: "Gdańsk University of Technology .NET Student Group",
            position: "Vice leader",
            when: [{
                since: new Date(2018, 7, 1),
                until: new Date(2021, 2, 1)
            }],
            projects: ["I was the vice leader of Grupa .NET PG at Gdańsk University of Technology where I occasionally conducted lectures about various aspects regarding .NET technology and computer programming."]
        },
        {
            company: "Microsoft",
            position: "Student Partner/Ambassador",
            when: [{
                since: new Date(2020, 3, 1),
                until: new Date(2021, 10, 1),
            }],
            projects: ["As an active promoter of .NET technology at my University I was granted with Microsoft Student Partner (program changed name to Student Ambassadors) title. "]
        }
    ],
    achievements: [
        {what: 'Speaker', where: 'Workshop hosted at Future3 2019 - Job Fair '},
        {what: 'Coorganizer', where: 'Zombathon - a hackathon for high school and university students'},
    ],
    skills: ['.NET', 'C#', 'React', 'Azure', 'SQL', 'Terraform', 'Helm', 'Kubernetes', 'Docker', 'DevOps', 'Agile', 'Rx.NET',
        'Domain Driven Design', 'TDD', 'Unit testing', 'Automation', 'Reactive programming', 'PowerShell', 'Git'].map(v => CreateSkillData(v)),
}

const boldStyle = {root: {fontWeight: FontWeights.semibold, marginRight: "5px"}};

const dateStyle = {
    color: theme.semanticColors.bodySubtext,
}

interface SectionProps {
    name: string;
    children: ReactNode;
    styles?: any;
}

const TitledSection: FunctionComponent<SectionProps> = ({children, name, styles}: SectionProps) => (
    <div
        style={{
            boxShadow: theme.effects.elevation8,
            marginTop: '1em',
            padding: '1em',
            backgroundColor: theme.palette.white,
            ...styles
        }}
    >
        <Stack style={{marginLeft: '1em', marginBottom: '0.5em', paddingRight: '0.5em'}}>
            <Text
                variant='xxLarge'
                style={{marginBottom: '0.2em'}}>
                {name}
            </Text>

            {children}
        </Stack>
    </div>
)

interface ProjectSectionProps {
    projects: IProject[]
}

const ProjectSection = ({projects}: ProjectSectionProps) => (
    <Stack>
        {projects.map(p =>
            <Stack key={p.name}>
                <Text variant='large'>{p.name}</Text>
                <Text>{p.domain}</Text>
                <DutiesSection duties={p.duties ?? []}/>
            </Stack>)}
    </Stack>
)

interface DutiesSectionProps {
    duties: string[]
}

const DutiesSection = ({duties}: DutiesSectionProps) => (
    <Stack>
        {
            duties.length !== 1 ?
                <ul style={{marginTop: '0.1em'}}>
                    {
                        duties.map(p => <li key={p}
                                            style={
                                                {fontFamily: 'Segoe UI', color: theme.semanticColors.listText}}>{p}
                        </li>)}
                </ul>
                : <Text>{duties[0]}</Text>
        }
    </Stack>
)

const JobSection = (job: IJobExperience, separator: boolean = false) => (
    <Stack key={job.company + job.position}>
        <div>
            <Text styles={boldStyle} variant='large'>{job.position}</Text><Text>@{job.company}</Text>
        </div>
        <div>
            {job.when.map(dr => (
                <Text {...dateStyle} >
                    {`${dr.since.getMonth()}.${dr.since.getFullYear()} - 
                ${dr.until ? dr.until.getMonth().toString() + '.' + dr.until.getFullYear().toString() : 'Currently'}`}
                    <br/>
                </Text>))}
        </div>
        <div>
            {
                (job.projects.length && typeof job.projects[0] === 'string') ?
                    <DutiesSection duties={job.projects as string[]}/>
                    : <ProjectSection projects={job.projects as IProject[]}/>
            }
        </div>
        {separator && <Separator/>}
    </Stack>
);


const OnMobile = (props: {
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
    return isTabletOrMobile ? <>{props.children}</> : <></>
}

const OnDesktop = (props: {
    children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
}) => {
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});
    return !isTabletOrMobile ? <>{props.children}</> : <></>
}

interface SkillProps {
    skill: SkillData;
}

const Skill = (props: SkillProps) => (
    <Text
        style={{
            padding: '0.5em',
            margin: '0.5em',
            marginTop: '0',
            boxShadow: theme.effects.elevation8
        }}
    >{props.skill.name}</Text>
)

const Skills = () => (
    <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent:'center'
    }}>
        {data.skills.map(s => <Skill key={s.name} skill={s}/>)}
    </div>
)

const LanguagesSection = () => (
    <TitledSection name='Languages'>
        <Text styles={boldStyle} variant='mediumPlus'>English</Text>
        <Text>Fluent <br/> ACERT Certified C1</Text>
        <Separator/>
        <Text styles={boldStyle} variant='mediumPlus'>Polish</Text>
        <Text style={{color: theme.semanticColors.bodySubtext}}>Native</Text>
    </TitledSection>
)

const AchievementsSectionBody: FunctionComponent = () => (
    <>
        {data.achievements.map(a =>
            <Stack key={a.what + a.where}>
                <Text styles={boldStyle} variant='large'>{a.what}</Text>
                <Text variant='medium'>{a.where}</Text>
                {data.achievements.indexOf(a) !== data.achievements.length - 1 && <Separator/>}
            </Stack>
        )}
    </>
)

const AdditionalInfoSection: FunctionComponent = (style: any) => (
    <>
        <Text
            style={{marginTop: '0.5em', color: theme.semanticColors.bodySubtext}}
            variant='small'>Page built with React and Microsoft Fluent UI components</Text>
        {/*<Text*/}
        {/*    style={{marginTop: '0.5em', color: theme.semanticColors.bodySubtext}}*/}
        {/*    variant='small'>*/}
        {/*    Available on <Link href='https://cv.beresniewicz.dev'>cv.beresniewicz.dev</Link>*/}
        {/*</Text>*/}
        <Text
            variant='small'
            style={{marginTop: '0.5em', color: theme.semanticColors.bodySubtext}}>
            I hereby give consent for my personal data included in the application to be processed for the purposes
            of the recruitment process in accordance with Art. 6 paragraph 1 letter a of the Regulation of the
            European Parliament and of the Council (EU) 2016/679 of 27 April 2016 on the protection of natural
            persons with regard to the processing of personal data and on the free movement of such data, and
            repealing Directive 95/46/EC (General Data Protection Regulation).
        </Text>
    </>
)

const Introduction = () => {
    const imageProps: Partial<IImageProps> = {
        imageFit: ImageFit.centerContain,
        width: 200,
        height: 200,
        src: cv,
    }

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

    return <Stack horizontal={!isTabletOrMobile}
                  verticalAlign={"center"} >

        <Stack.Item align={"center"}>
            <div style={{display: 'flex', justifyContent: 'center', marginRight: 0, paddingRight: 0}}>
                <Image style={{alignSelf: 'center', width: 200, height: 200, borderRadius: '50%'}}{...imageProps}/>
            </div>
        </Stack.Item>

        <Stack.Item align={"center"} style={{marginLeft: isTabletOrMobile? '0em' : '2em'}}>
            <Stack horizontal={!isTabletOrMobile} >
                <Text variant='xxLargePlus' styles={boldStyle} style={{textWrap: "nowrap"}}>Karol Bereśniewicz</Text>
                <Text variant='xxLargePlus' style={{textWrap: "nowrap"}}>{!isTabletOrMobile ? '|' : ''} .NET Fullstack
                    Engineer</Text>
            </Stack>

            <Text variant='mediumPlus'>
                I am focused on finding the right tools to solve business problems.<br/>
                Always happy to both share and receive knowledge and looking for ways to improve his
                craftsmanship.</Text>
        </Stack.Item>
    </Stack>
}